import React, { useState, useEffect } from "react";
import Title from "@components/common/Title";
import Layout from "@components/layouts/Layout";
import SmallBanner from "@components/common/SmallBanner";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Component = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });

    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(
                x => x.enabled && !x.hidden && x?.category?.includes("cursos-policiales"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setFilteredItems(products);
        }
    }, [result]);

    const administrativo = filteredItems?.filter(x => x.category?.includes("administrativo"));
    const asistencial = filteredItems?.filter(x => x.category?.includes("asistencial"));
    const constitucional = filteredItems?.filter(x => x.category?.includes("constitucional"));
    const criminologia = filteredItems?.filter(x => x.category?.includes("criminologia"));
    const penal = filteredItems?.filter(x => x.category?.includes("penal"));
    const transito = filteredItems?.filter(x => x.category?.includes("transito"));
    const transportes = filteredItems?.filter(x => x.category?.includes("transportes"));

    return (
        <>
            {process.env.GATSBY_ENABLE_POL_COURSES === "true" && (
                <Layout
                    seo={{
                        title: "Cursos Policiales",
                        description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    }}
                >
                    <SmallBanner
                        title="Cursos Policiales"
                        alt="cursos-policiales"
                        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        filename="bgs/bgCursosPoliciales"
                    />

                    <div className="container lg:px-0 pt-10 pb-20 gap-10 grid grid-cols-1">
                        {/** Administrativo */}
                        <div className="bg-gray-50">
                            <Title>Administrativo</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={administrativo}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={administrativo} />
                                )}
                            </div>
                        </div>

                        {/** Asistencial */}
                        <div className="bg-gray-50">
                            <Title>Asistencial</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={asistencial}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={asistencial} />
                                )}
                            </div>
                        </div>

                        {/** Constitucional */}
                        <div className="bg-gray-50">
                            <Title>Constitucional</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={constitucional}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={constitucional} />
                                )}
                            </div>
                        </div>

                        {/** Criminologia */}
                        <div className="bg-gray-50">
                            <Title>Criminología</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={criminologia}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={criminologia} />
                                )}
                            </div>
                        </div>

                        {/** Penal */}
                        <div className="bg-gray-50">
                            <Title>Penal</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={penal}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={penal} />
                                )}
                            </div>
                        </div>

                        {/** Transito */}
                        <div className="bg-gray-50">
                            <Title>Tránsito</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={transito}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={transito} />
                                )}
                            </div>
                        </div>

                        {/** Transportes */}
                        <div className="bg-gray-50">
                            <Title>Transportes</Title>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Text>

                            <div className="mt-10 space-y-5">
                                {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                    <ProductsNew
                                        items={transportes}
                                        loading={isLoading || isValidating}
                                    />
                                ) : (
                                    <Products items={transportes} />
                                )}
                            </div>
                        </div>
                    </div>
                </Layout>
            )}
        </>
    );
};

export default Component;
